import Product from './Product/Product';
import styles from './styles.module.scss';

const Products = ({ cards, title, filtering }) => (
  <div className={styles['products-content']}>
    <div className={styles.container}>
      {title}
      {filtering || null}
      <div className={styles['products-wrapper']}>
        {cards.map((card) => (
          <Product
            key={card.id}
            title={card.title}
            pic={card.pic}
            category={card.category}
            cardId={card.id}
          />
        ))}
      </div>
    </div>
  </div>
);
export default Products;
