import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

const Product = ({
  pic, title, category, cardId,
}) => (
  <div className={styles.card}>
    <div className={styles['card-inner']}>
      <NavLink to={`/product/${cardId}`}>
        <div className={styles['card-image']}>
          <img src={pic} alt="product" />
        </div>
      </NavLink>
      <span className={styles['card-title']}>{title}</span>
      <div className={styles['card-tab']}>{category}</div>
      <div className={styles['card-buttons']}>
        {/* <button className={styles.btn} type="button">Купить</button> */}
        <NavLink to={`/product/${cardId}`}>
          <button className={`${styles.btn} ${styles['light-light-green']}`} type="button">Открыть</button>
        </NavLink>
      </div>
    </div>
  </div>
);
export default Product;
