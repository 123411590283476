import { createSlice } from '@reduxjs/toolkit';
import { getReviews } from './reviewsServices';

const initialState = {
  reviews: null,
  loading: false,
};
const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getReviews.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        state.reviews = action.payload;
        state.loading = false;
      })
      .addCase(getReviews.rejected, (state) => {
        state.loading = false;
      });
  },
});
export default reviewsSlice.reducer;
