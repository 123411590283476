import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Control from './Control/Control';
import LowerNav from './LowerNav/LowerNav';
import styles from './styles.module.scss';
import UpperNav from './UpperNav/UpperNav';
import BurgerBtn from '../BurgerBtn/BurgerBtn';
import Logo from './Logo/Logo';
import MobileMenu from '../MobileMenu/MobileMenu';

const Header = () => {
  const [mobile, setMobile] = useState(false);
  const [scroll, setScroll] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setMobile(false);
  }, [location.pathname]);

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };
  useEffect(() => {
    document.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, [window.scrollY]);

  return (
    <header className={styles[`${scroll ? 'header-scroll' : 'header'}`]}>
      <div className={styles['header-up']}>
        <div className={styles.container}>
          <div className={styles['header-up-wrapper']}>
            <Logo />
            <UpperNav />
            <Control />
            {/* for mobile menu */}
            <div className={styles.link}>
              <a href="tel:+78001005153">+7 (800) 100-5153</a>
            </div>
            <button type="button" className={styles.cartBtn}>
              <img src="./pic/icons/cart.svg" alt="кнопка корзины" />
            </button>
            <BurgerBtn showBtn={() => setMobile(!mobile)} />
          </div>
        </div>
      </div>
      <div className={styles['header-down']}>
        <div className={styles.container}>
          <LowerNav />
        </div>
      </div>
      <MobileMenu isVisible={mobile} />
    </header>
  );
};
export default Header;
