import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Collage from '../../components/Collage/Collage';
import Advice from '../../components/Advice/Advice';
import Category from '../../components/Category/Category';
import Intro from '../../components/Intro/Intro';
import Products from '../../components/Products/Products';
import Descr from '../../components/Collage/Descr/Descr';
import Image from '../../components/Collage/Image/Image';
import Title from '../../components/generic/Title/Title';
import { getCards } from '../../redux/cardsSlice/cardsService';
import styles from './styles.module.scss';
import SEO from '../../components/SEO/SEO';
import uri from '../../utils/api/uri';

const data = {
  intro: {
    pic: './pic/intro/intro-bg_1.jpg',
    title: 'Когда в последний раз вы оказывали себе услугу?',
  },
  products: {
    cards: [
      {
        id: 1,
        pic: './pic/products/product-img_1.jpg',
        category: 'Уход за телом',
        title: 'Ecowell Aloe Vera Jel (200 ml)',
      },
      {
        id: 2,
        pic: './pic/products/product-img_2.jpg',
        category: 'Продукты Для Ухода За Лицом',
        title: 'Молоко для снятия макияжа Ecowell (150 мл)',
      },
      {
        id: 3,
        pic: './pic/products/product-img_3.jpg',
        category: 'Продукты По Уходу За Ребенком',
        title: 'Ecowell Органический детский солнцезащитный крем 50 SPF',
      },
      {
        id: 4,
        pic: './pic/products/product-img_4.jpg',
        category: 'Уход за телом',
        title: 'Органический лосьон для загара Ecowell 30 SPF (150 мл)',
      },
      {
        id: 5,
        pic: './pic/products/product-img_5.jpg',
        category: 'Уход за телом',
        title: 'Ecowell Organic Roll-on Male 75 мл',
      },
      {
        id: 6,
        pic: './pic/products/product-img_6.jpg',
        category: 'Продукты Для Ухода За Лицом',
        title: 'Ecowell Organic Roll-on Women (75 мл)',
      },
      {
        id: 7,
        pic: './pic/products/product-img_7.jpg',
        category: 'Продукты По Уходу За Ребенком',
        title: 'Ecowell Органический шампунь для новорожденных с пеной',
      },
      {
        id: 8,
        pic: './pic/products/product-img_8.jpg',
        category: 'Уход За Волосами',
        title: 'Органический лосьон для загара Ecowell 30 SPF (150 мл)',
      },
    ],
  },
  advice: {
    cards: [
      {
        id: 1,
        pic: './pic/advice/advice-img_1.jpg',
        subtitle: 'Что мы можем сделать для обесцвечивания солнечных пятен на нашей коже?',
        descr: '',
        date: '',
      },
      {
        id: 2,
        pic: './pic/advice/advice-img_2.jpg',
        subtitle: 'То, чем мы не можем насытиться- это сертифицированный органический уход!',
        descr: '',
        date: '',
      },
      {
        id: 3,
        pic: './pic/advice/advice-img_3.jpg',
        subtitle: 'Почему наши волосы выпадают?',
        descr: '',
        date: '',
      },
    ],

  },
  collage: {
    pic1: './pic/collage/collage-img_1.jpg',
    descr: {
      title: 'Наша разница',
      text: ['Продукты Ecowell сертифицированы ICEA, являются натуральными и органическими.Продукты Ecowell никогда не содержат вредных для здоровья ингредиентов.'],
    },
    pic2: './pic/collage/collage-img_2.jpg',
  },
  category: {
    categories: [
      {
        id: 1,
        pic: './pic/category/category-img_1.jpg',
        title: 'Продукты По Уходу За Ребенком',
        catLink: '/baby',
      },
      {
        id: 2,
        pic: './pic/category/category-img_2.jpg',
        title: 'Продукты По Уходу За Волосами',
        catLink: '/hair',
      },
      {
        id: 3,
        pic: './pic/category/category-img_3.jpg',
        title: 'Продукты По Уходу За Телом',
        catLink: '/body',
      },
      {
        id: 4,
        pic: './pic/category/category-img_4.jpg',
        title: 'Продукты По Уходу За Лицом',
        catLink: '/face',
      },
      {
        id: 5,
        pic: './pic/category/category-img_5.jpg',
        mobile: './pic/category/category-img_5-480w.jpg',
        title: 'Продукты По Уходу За Ребенком',
        catLink: '/baby',
      },

    ],
  },
};
const MainPage = () => {
  const [cardsList, setCardsList] = useState([]);
  const dispatch = useDispatch();
  const { cards } = useSelector((state) => state.cards);

  useEffect(() => {
    if (cards.length === 0) {
      dispatch(getCards(uri.cards));
    } else {
      setCardsList([...cards]);
    }
  }, [cards]);
  return (
    <div className={styles.content}>
      <SEO mark="main" />
      <Intro pic={data.intro.pic} title={data.intro.title} />
      <Products
        cards={cardsList}
        title={<Title heading="Рекомендуемые продукты" />}
      />
      <Advice
        cards={data.advice.cards}
        title={<Title heading="Последние сообщения" />}
      />
      <Collage bgColor="#F8F8FA">
        <Descr title={data.collage.descr.title} text={data.collage.descr.text} classProp="first" />
        <Image pic={data.collage.pic1} classProp="first" />
        <Image pic={data.collage.pic2} classProp="second" />
      </Collage>
      <Category categories={data.category.categories} />
    </div>
  );
};
export default MainPage;
