import { configureStore } from '@reduxjs/toolkit';
import cardsReducer from './cardsSlice/cardsSlice';
import seoReducer from './seo/seoSlice';
import reviewsReducer from './reviews/reviewsSlice';

export const store = configureStore({
  reducer: {
    cards: cardsReducer,
    seo: seoReducer,
    reviews: reviewsReducer,
  },
});
