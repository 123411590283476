import Slider from 'react-slick';
import './styles.scss';
// Import css files
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
/* eslint-disable react/jsx-props-no-spreading */
import { ArrowLeft, ArrowRight } from '../generic/Arrows/Arrows';
import CardSlide from './CardSlide/CardSlide';

const CardsSlider = ({ cards }) => {
  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    // adaptiveHeight: true,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1490,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="cards-slider-wrapper">
      <h3 className="prod-title">Рекомендуемые продукты</h3>
      <Slider
        {...settings}
        className="cards-slider"
      >
        {cards.map((card) => (
          <CardSlide
            key={card.id}
            pic={card.pic}
            title={card.title}
            category={card.category}
            cardId={card.id}
          />
        ))}
      </Slider>
    </div>
  );
};
export default CardsSlider;
