import styles from './styles.module.scss';

const Filter = () => (
  <div className={styles.filter}>
    <ul className={styles['filter-list']}>
      <li className={styles['filter-item']}>
        <div className={styles['filter-item-wrapper']}>
          Всего:
          <span className={styles['filter-item-info']}>
            33 продукта
          </span>
        </div>
      </li>
      <li className={styles['filter-item']}>
        <div className={styles['filter-item-wrapper']}>
          Сортировать по:
          <span className={styles['filter-item-info']}>
            Цене
          </span>
          <span className={styles['filter-item-arrows']}>
            <span className={`${styles['filter-item-arrow']} ${styles.up}`}>
              <img src="./pic/arrows/prev-arrow.png" alt="стрелка вверх" />
            </span>
            <span className={`${styles['filter-item-arrow']} ${styles.down}`}>
              <img src="./pic/arrows/next-arrow.png" alt="стрелка вниз" />
            </span>
          </span>
        </div>
      </li>
      <li className={styles['filter-item']}>
        <div className={styles['filter-item-wrapper']}>
          Сортировать по:
          <span className={styles['filter-item-info']}>
            Алфавиту
          </span>
          <span className={styles['filter-item-arrows']}>
            <span className={`${styles['filter-item-arrow']} ${styles.up}`}>
              <img src="./pic/arrows/prev-arrow.png" alt="стрелка вверх" />
            </span>
            <span className={`${styles['filter-item-arrow']} ${styles.down}`}>
              <img src="./pic/arrows/next-arrow.png" alt="стрелка вниз" />
            </span>
          </span>
        </div>
      </li>
    </ul>
  </div>
);
export default Filter;
