import styles from './styles.module.scss';
// Import css files
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';

export const ArrowLeft = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} ${styles['custom-arrow']} ${styles['arrow-prev']}`}
      style={
          {
            ...style,
            display: 'block',
          }
}
      onClick={onClick}
    >
      <img src="../pic/arrows/prev-arrow.png" alt="arrow" />
    </div>

  );
};
export const ArrowRight = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} ${styles['custom-arrow']} ${styles['arrow-next']}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src="../pic/arrows/next-arrow.png" alt="arrow" />
    </div>
  );
};
