import { useState } from 'react';
import { createPortal } from 'react-dom';
import ModalForm from './ModalForm/ModalForm';
import { useOutsideClick } from '../../utils/hooks/useOutsideClick';
import styles from './styles.module.scss';
import { sendEmail } from '../../utils/api/sendEmail';
import Privacy from '../Privacy/Privacy';

const Modal = ({ isOpen, onClose }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [message, setMessage] = useState('');
  const handleSubmit = async (userData) => {
    setIsFetching(true);
    const response = await sendEmail(userData);
    if (response === 201) {
      setIsFetching(false);
      setMessage('Успешно!');
      userData.firstName = '';
      userData.text = '';
      userData.email = '';
    } else {
      setIsFetching(false);
      setMessage('Не удалось отправить');
    }
  };
  const { rootEl } = useOutsideClick(onClose);
  if (!isOpen) return null;
  return createPortal(
    <>
      <div className={styles['modal-overlay']} ref={rootEl} />
      <div className={styles.modal}>
        <div className={styles['modal-container']}>
          <div className={styles['btn-close']} onClick={onClose}>
            <img src="/pic/icons/close-btn.svg" alt="кнопка закрыть" />
          </div>
          <div className={styles.message}>{message}</div>
          <div className={styles['modal-wrapper']}>
            <h2 className={styles['modal-title']}>Свяжитесь с нами</h2>
            <ModalForm isFetching={isFetching} onHandleSubmit={handleSubmit} />
            <div className={styles['modal-info']}>
              <p>
                Оставляя сообщение, вы соглашаетесь с нашей
                {' '}
                <span onClick={() => setIsPrivacyOpen(true)}>
                  Политикой конфиденциальности.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Privacy isOpen={isPrivacyOpen} onClose={() => setIsPrivacyOpen(false)} />
    </>,
    document.getElementById('modal'),
  );
};
export default Modal;
