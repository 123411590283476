import styles from './styles.module.scss';

const Collage = ({ bgColor, children }) => (
  <div
    className={styles.collage}
    style={{ backgroundColor: `${bgColor}` }}
  >
    <div className={styles.container}>
      <div className={styles['collage-wrapper']}>
        {children}
      </div>
    </div>
  </div>
);

export default Collage;
