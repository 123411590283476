import styles from './styles.module.scss';

const Social = () => (
  <div className={styles.social}>
    <div className={styles['social-wrapper']}>
      <img src="/pic/social/instagram.svg" alt="instagram" />
      <img src="/pic/social/vk.svg" alt="vk" />
      <img src="/pic/social/dzen.svg" alt="dzen" />
    </div>
  </div>
);
export default Social;
