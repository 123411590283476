import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const Logo = () => (
  <div className={styles.logo}>
    <Link className={styles['logo-link']} to="/">
      <img src="/logo.svg" alt="logo" />
    </Link>
  </div>
);
export default Logo;
