import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const LowerNav = () => (
  <div className={styles['nav-wrapper']}>
    <nav className={styles.nav}>
      <ul className={styles['nav-list']}>
        <li className={`${styles['nav-item']} ${styles.first}`}>
          <Link className={styles['nav-link']} to="/face">
            Продукты для ухода за лицом
          </Link>
        </li>
        <li className={`${styles['nav-item']} ${styles.second}`}>
          <Link className={styles['nav-link']} to="/body">
            Уход за телом
          </Link>
        </li>
        <li className={`${styles['nav-item']} ${styles.third}`}>
          <Link className={styles['nav-link']} to="/hair">
            Уход за волосами
          </Link>
        </li>
        <li className={`${styles['nav-item']} ${styles.fourth}`}>
          <Link className={styles['nav-link']} to="/baby">
            Продукты по уходу за ребенком
          </Link>
        </li>
        {/* <li className={`${styles['nav-item']} ${styles.fifth}`}> */}
        {/*  <Link className={styles['nav-link']} to="/diamond"> */}
        {/*    Серия DIAMOND */}
        {/*  </Link> */}
        {/* </li> */}
      </ul>
    </nav>
  </div>
);
export default LowerNav;
