import styles from './styles.module.scss';

const ProdDescr = ({ description }) => (
  <div className={styles['prod-descr']}>
    <div className={styles.container}>
      <h3 className={styles['prod-title']}>Информация о продукте</h3>
      <div className={styles['prod-descr-content']}>
        {description?.description && (
          <div className={styles['prod-descr-paragraph']}>
            <h6 className={styles['prod-descr-subtitle']}>
              О продукте
            </h6>
            <p>
              {description.description}
            </p>
          </div>
        )}
        {description?.suggested_use && (
          <div className={styles['prod-descr-paragraph']}>
            <h6 className={styles['prod-descr-subtitle']}>
              Рекомендации по использованию
            </h6>
            <p>
              {description.suggested_use}
            </p>
          </div>
        )}
        {description?.other_ingredients && (
          <div className={styles['prod-descr-paragraph']}>
            <h6 className={styles['prod-descr-subtitle']}>
              Другие ингридиенты
            </h6>
            <p>
              {description.other_ingredients}
            </p>
          </div>
        )}
        {description?.warnings && (
          <div className={styles['prod-descr-paragraph']}>
            <h6 className={styles['prod-descr-subtitle']}>
              Остережения
            </h6>
            <p>
              {description.warnings}
            </p>
          </div>
        )}
        {description?.disclaimer && (
          <div className={styles['prod-descr-paragraph']}>
            <h6 className={styles['prod-descr-subtitle']}>
              Отказ
            </h6>
            <p>
              {description.disclaimer}
            </p>
          </div>
        )}
      </div>
    </div>
  </div>

);
export default ProdDescr;
