import axios from "axios";
import uri from "./uri";
/* eslint quotes: ["error", "double"] */
/* eslint quote-props: ["error", "consistent"] */
/* eslint-env es6 */

export const sendEmail = (userData) => {
  const recipients = ["abazaliev.ar@zerolab.tech"];
  const { firstName, text, email } = userData;
  return axios.post(uri.emails, {
    "email_from": "noreply@mail.e-well.ru",
    "name_from": firstName,
    "mail_to": recipients[0],
    "subject": email,
    "text": text,
  }, {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.status).catch((res) => res.status);
};
