import Contacts from '../../components/Contacts/Contacts';
import Collage from '../../components/Collage/Collage';
import Descr from '../../components/Collage/Descr/Descr';
import Image from '../../components/Collage/Image/Image';
import Category from '../../components/Category/Category';
import styles from './styles.module.scss';
import SEO from '../../components/SEO/SEO';

const data = {
  collage: {
    pic1: './pic/collage/collage-img_1.jpg',
    descr: {
      title: 'Наша разница',
      text: ['Продукты Ecowell сертифицированы ICEA, являются натуральными и органическими.Продукты Ecowell никогда не содержат вредных для здоровья ингредиентов.'],
    },
    pic2: './pic/collage/collage-img_2.jpg',
  },
  category: {
    categories: [
      {
        id: 1,
        pic: './pic/category/category-img_1.jpg',
        title: 'Продукты По Уходу За Ребенком',
        catLink: '/baby',
      },
      {
        id: 2,
        pic: './pic/category/category-img_2.jpg',
        title: 'Продукты По Уходу За Волосами',
        catLink: '/hair',
      },
      {
        id: 3,
        pic: './pic/category/category-img_3.jpg',
        title: 'Продукты По Уходу За Телом',
        catLink: '/body',
      },
      {
        id: 4,
        pic: './pic/category/category-img_4.jpg',
        title: 'Продукты По Уходу За Лицом',
        catLink: '/face',
      },
      {
        id: 5,
        pic: './pic/category/category-img_5.jpg',
        mobile: './pic/category/category-img_5-480w.jpg',
        title: 'Продукты По Уходу За Ребенком',
        catLink: '/baby',
      },

    ],
  },
};

const ContactsPage = () => (
  <div className={styles.content}>
    <SEO mark="contacts" />
    <Contacts />
    <Collage bgColor="#F8F8FA">
      <Descr title={data.collage.descr.title} text={data.collage.descr.text} />
      <Image pic={data.collage.pic1} classProp="first" />
      <Image pic={data.collage.pic2} classProp="second" />
    </Collage>
    <Category categories={data.category.categories} />

  </div>
);
export default ContactsPage;
