import { useRef, useEffect } from 'react';

export const useOutsideClick = (callback) => {
  const rootEl = useRef(null);
  useEffect(() => {
    const onClick = (e) => {
      if (rootEl.current !== null && rootEl.current.contains(e.target)) {
        console.log(rootEl);
        callback();
      }
    };
    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, []);
  return { rootEl };
};
