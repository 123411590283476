import { useState } from 'react';
import FooterCategory from './FooterCategory/FooterCategory';
import FooterNav from './FooterNav/FooterNav';
import Aside from './Aside/Aside';
import styles from './styles.module.scss';
import Button from '../generic/Button/Button';
import Modal from '../Modal/Modal';

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles['footer-wrapper']}>
          <FooterNav />
          <FooterCategory />
          <Aside />
          <Button onOpen={() => setIsModalOpen(true)} className={styles['btn-footer']} />
        </div>
        <span className={styles.rigths}>{`@${new Date().getFullYear()} Ecowell`}</span>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </footer>
  );
};
export default Footer;
