import Social from './Social/Social';
import styles from './styles.module.scss';

const Intro = ({ pic, title }) => (
  <div
    className={styles.intro}
    style={{ backgroundImage: `url(${pic})`, backgroundSize: 'cover' }}
  >
    <h1 className={styles.introTitle}>{title}</h1>
    <Social />
  </div>
);
export default Intro;
