import AdviceBlock from './AdviceBlock/AdviceBlock';
import AdviceContent from './AdviceContent/AdviceContent';
import styles from './styles.module.scss';

const Advice = ({
  cards, title,
}) => (
  <div className={`${styles.advice} ${styles.blog}`}>
    <div className={styles.container}>
      {title}
      <div className={`${styles['advice-wrapper']} ${styles['advice-wrapper-blog']}`}>
        {cards.map((card) => (
          <AdviceBlock
            key={card.id}
            pic={card.pic}
          >
            <AdviceContent subtitle={card?.subtitle} descr={card?.descr} date={card?.date} />
          </AdviceBlock>
        ))}
      </div>
    </div>
  </div>
);
export default Advice;
