import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CardsSlider from '../../components/CardsSlider/CardsSlider';
import FeedbackSlider from '../../components/FbackSlider/FbackSlider';
import ProductHead from '../../components/ProductCard/ProductHead/ProductHead';
import ProdDescr from '../../components/ProductCard/ProdDescr/ProdDescr';
import { getCards } from '../../redux/cardsSlice/cardsService';
import SEO from '../../components/SEO/SEO';
import { getReviews } from '../../redux/reviews/reviewsServices';
import uri from '../../utils/api/uri';
/* eslint camelcase: ["error",
 {"properties": "never", ignoreDestructuring: true, allow: ["^UNSAFE_"]}] */

const ProductPage = () => {
  const [cardsList, setCardsList] = useState([]);
  const [reviewsList, setReviewList] = useState([]);
  const dispatch = useDispatch();
  const { cards } = useSelector((state) => state.cards);
  const { reviews } = useSelector((state) => state.reviews);
  const { cardId } = useParams();

  useEffect(() => {
    if (cards.length === 0) {
      dispatch(getCards(uri.cards));
    } else {
      setCardsList([...cards]);
    }
  }, [dispatch, cards]);

  useEffect(() => {
    if (!reviews) {
      dispatch(getReviews(uri.reviews));
    } else {
      setReviewList([...reviews]);
    }
  }, [dispatch, reviews]);

  const product = useMemo(() => {
    let result = {};
    cardsList.forEach((card) => {
      if (card.id === +cardId) {
        result = card;
      }
    });
    return result;
  }, [cardId, cardsList]);

  const {
    title,
    about,
    description,
    pic_slider,
    disclaimer,
    other_ingredients,
    suggested_use,
    warnings,
    vendor_code,
    volume,

  } = product;
  const prodDescription = {
    about,
    description,
    disclaimer,
    other_ingredients,
    suggested_use,
    warnings,

  };
  const reviewCard = reviewsList.filter((item) => item.card === product.id);
  return (
    <>
      <SEO mark="products" />
      <ProductHead
      // eslint-disable-next-line camelcase
        images={pic_slider}
        title={title}
        description={description}
        volume={volume}
        // eslint-disable-next-line camelcase
        vendorCode={vendor_code}
      />
      <ProdDescr description={prodDescription} />
      {reviewCard.length > 0 ? <FeedbackSlider reviews={reviewCard} /> : null}
      <CardsSlider cards={cardsList} />
    </>
  );
};
export default ProductPage;
