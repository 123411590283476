import styles from './styles.module.scss';

const Button = ({ onOpen, className }) => (
  <button
    onClick={onOpen}
    type="button"
    className={`${styles.btn} ${className}`}
  >
    Свяжитесь с нами
  </button>
);
export default Button;
