import AboutProd from '../../components/AboutProd/AboutProd';
import Collage from '../../components/Collage/Collage';
import Descr from '../../components/Collage/Descr/Descr';
import Image from '../../components/Collage/Image/Image';
import Intro from '../../components/Intro/Intro';
import Category from '../../components/Category/Category';
import styles from './styles.module.scss';
import SEO from '../../components/SEO/SEO';

const data = {
  intro: {
    pic: './pic/intro/intro-bg_3.jpg',
    title: 'Наша разница',
  },
  collage: {
    pic1: './pic/collage/collage-img_5.jpg',
    pic2: './pic/collage/collage-img_6.jpg',
    pic3: './pic/collage/collage-img_1.jpg',
    pic4: './pic/collage/collage-img_2.jpg',

    descr: [
      {
        id: 1,
        title: 'ПРОДУКТЫ ECOWELL СЕРТИФИЦИРОВАНЫ ICEA, ЯВЛЯЮТСЯ НАТУРАЛЬНЫМИ И ОРГАНИЧЕСКИМИ.',
      },
      {
        id: 2,
        title: 'ПРОДУКТЫ ECOWELL НЕ СОДЕРЖАТ ВРЕДНЫХ ДЛЯ ЗДОРОВЬЯ ИНГРЕДИЕНТОВ.',
      },
      {
        id: 3,
        title: 'Наша разница',
        text: [
          'Продукты Ecowell сертифицированы ICEA, являются натуральными и органическими.Продукты Ecowell никогда не содержат вредных для здоровья ингредиентов.',
        ],
      },
    ],
  },
  category: {
    categories: [
      {
        id: 1,
        pic: './pic/category/category-img_1.jpg',
        title: 'Продукты По Уходу За Ребенком',
        catLink: '/baby',
      },
      {
        id: 2,
        pic: './pic/category/category-img_2.jpg',
        title: 'Продукты По Уходу За Волосами',
        catLink: '/hair',
      },
      {
        id: 3,
        pic: './pic/category/category-img_3.jpg',
        title: 'Продукты По Уходу За Телом',
        catLink: '/body',
      },
      {
        id: 4,
        pic: './pic/category/category-img_4.jpg',
        title: 'Продукты По Уходу За Лицом',
        catLink: '/face',
      },
      {
        id: 5,
        pic: './pic/category/category-img_5.jpg',
        mobile: './pic/category/category-img_5-480w.jpg',
        title: 'Продукты По Уходу За Ребенком',
        catLink: '/baby',
      },

    ],
  },
};

const ContrastPage = () => (
  <div className={styles.container}>
    <SEO mark="contrast" />
    <Intro title={data.intro.title} pic={data.intro.pic} />
    <Collage bgColor="#F8F8FA">
      <Descr title={data.collage.descr[0].title} classProp="first" />
      <Image pic={data.collage.pic1} classProp="first" />
      <Image pic={data.collage.pic2} classProp="second" />
      <Descr title={data.collage.descr[1].title} classProp="second" />
    </Collage>
    <AboutProd />
    <Collage bgColor="#F8F8FA">
      <Descr title={data.collage.descr[2].title} text={data.collage.descr[2].text} classProp="first" />
      <Image pic={data.collage.pic3} classProp="first" />
      <Image pic={data.collage.pic4} classProp="second" />
    </Collage>
    <Category categories={data.category.categories} />
  </div>
);
export default ContrastPage;
