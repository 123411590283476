import Advice from '../../components/Advice/Advice';
import Collage from '../../components/Collage/Collage';
import Intro from '../../components/Intro/Intro';
import Descr from '../../components/Collage/Descr/Descr';
import Image from '../../components/Collage/Image/Image';
import styles from './styles.module.scss';
import Category from '../../components/Category/Category';
import SEO from '../../components/SEO/SEO';

const data = {
  intro: {
    title: 'Блог',
    pic: './pic/intro/intro-bg_3.jpg',
  },
  advice: {
    cards: [
      {
        id: 1,
        pic: './pic/advice/advice-img_1.jpg',
        subtitle: 'Что мы можем сделать для обесцвечивания солнечных пятен на нашей коже?',
        descr: 'ЧТО МЫ МОЖЕМ СДЕЛАТЬ ДЛЯ ОБЕСЦВЕЧИВАНИЯ И СОЛНЕЧНЫХ ПЯТЕН НА НАШЕЙ КОЖЕ? Почему образуются пятна на коже? Можно избавиться от пятен на лице...',
        date: '04/11/2017',
      },
      {
        id: 2,
        pic: './pic/advice/advice-img_2.jpg',
        subtitle: 'То, чем мы не можем насытиться- это сертифицированный органический уход!',
        descr: 'Чудо природы младенцам, чудо природы продукты ... Кожа вашего ребенка в 3 раза тоньше и нежнее вашей. Взрослых на кожу...',
        date: '04/11/2017',
      },
      {
        id: 3,
        pic: './pic/advice/advice-img_3.jpg',
        subtitle: 'Почему наши волосы выпадают?',
        descr: 'Почему наши волосы выпадают? Есть ли способ предотвратить выпадение волос? Как и как часто мы должны ухаживать за волосами от выпадения? Волос...',
        date: '04/11/2017',
      },
    ],
  },
  collage: {
    pic1: './pic/collage/collage-img_1.jpg',
    descr: {
      title: 'Наша разница',
      text: ['Продукты Ecowell сертифицированы ICEA, являются натуральными и органическими.Продукты Ecowell никогда не содержат вредных для здоровья ингредиентов.'],
    },
    pic2: './pic/collage/collage-img_2.jpg',
  },
  category: {
    categories: [
      {
        id: 1,
        pic: './pic/category/category-img_1.jpg',
        title: 'Продукты По Уходу За Ребенком',
        catLink: '/baby',
      },
      {
        id: 2,
        pic: './pic/category/category-img_2.jpg',
        title: 'Продукты По Уходу За Волосами',
        catLink: '/hair',
      },
      {
        id: 3,
        pic: './pic/category/category-img_3.jpg',
        title: 'Продукты По Уходу За Телом',
        catLink: '/body',
      },
      {
        id: 4,
        pic: './pic/category/category-img_4.jpg',
        title: 'Продукты По Уходу За Лицом',
        catLink: '/face',
      },
      {
        id: 5,
        pic: './pic/category/category-img_5.jpg',
        mobile: './pic/category/category-img_5-480w.jpg',
        title: 'Продукты По Уходу За Ребенком',
        catLink: '/baby',
      },

    ],
  },
};

const BlogPage = () => (
  <div className={styles.content}>
    <SEO mark="blog" />
    <Intro pic={data.intro.pic} title={data.intro.title} />
    <Advice
      cards={data.advice.cards}
    />
    <Collage bgColor="#F8F8FA">
      <Descr title={data.collage.descr.title} text={data.collage.descr.text} />
      <Image pic={data.collage.pic1} classProp="first" />
      <Image pic={data.collage.pic2} classProp="second" />
    </Collage>
    <Category categories={data.category.categories} />
  </div>
);
export default BlogPage;
