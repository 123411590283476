import Title from '../generic/Title/Title';
import CategoryBlock from './CategoryBlock/CategoryBlock';
import styles from './styles.module.scss';

const Category = ({ categories }) => (
  <div className={styles.category}>
    <div className={styles.container}>
      <Title heading="Категории" />
      <div className={styles['category-wrapper']}>
        {categories.map((category) => (
          <CategoryBlock
            key={category.id}
            title={category.title}
            pic={category.pic}
            mobile={category.mobile}
            id={category.id}
            catLink={category.catLink}
          />
        ))}
      </div>
    </div>
  </div>
);
export default Category;
