import { Link } from 'react-router-dom';
import Title from '../generic/Title/Title';
import styles from './styles.module.scss';

const AboutProd = () => (
  <div className={styles.about}>
    <div className={styles.container}>
      <Title heading="Продукты Ecowell никогда не содержат следующих вредных веществ." />
      <div className={styles['about-info']}>
        <ul className={styles['about-info-list']}>
          <li>
            <span>Вредные синтетические химические вещества</span>
          </li>
          <li>
            <span>Кремний</span>
          </li>
          <li>
            <span>Парабен</span>
          </li>
          <li>
            <span>SLS и SLES</span>
          </li>
        </ul>
        <p className={styles['about-info-text']}>
          Продукты Ecowell содержат натуральные ароматизаторы,
          не содержащие аллергенов, органические эфирные масла,
          а также растительные экстракты и эссенции.
        </p>
        <p className={styles['about-info-text']}>
          Все продукты Ecowell сертифицированы ICEA ( аккредитованной
          на международном уровне и одной из самых надежных организаций
          по сертификации биокосмической продукции в Европе). Продукты,
          сертифицированные ICEA, проходят дерматологические и микробиологические испытания.
          ICEA гарантирует, что продукты содержат органические ингредиенты.
        </p>
        <span className={styles['about-info-subtext']}>Продукты Ecowell не тестируются на животных.</span>
      </div>
    </div>
    <div className={styles['about-banner']}>
      <h2 className={styles['about-banner-title']}>ОЗНАКОМЬТЕСЬ С НАШИМИ ПРОДУКТАМИ ПРЯМО СЕЙЧАС, НЕ ТЕРЯЯ ВРЕМЕНИ!</h2>
      <button className={`${styles.btn} ${styles.white} ${styles['banner-btn']}`} type="button">
        <Link to="/face">За покупками!</Link>
      </button>
    </div>
  </div>
);
export default AboutProd;
