import Product from '../../Products/Product/Product';
import styles from './styles.module.scss';

const CardSlide = ({
  pic, title, category, cardId,
}) => (
  <div className={styles['card-slide']}>
    <Product
      pic={pic}
      title={title}
      category={category}
      cardId={cardId}
    />
  </div>

);
export default CardSlide;
