import styles from './styles.module.scss';

const Info = () => (
  <div className={styles.content}>
    <div className={styles.info}>
      <span>ООО Р-Трейд</span>
      <span>
        Нижегородская обл., г.о.город Нижний Новгород, ул. Ванеева,Д. 127, ПОМЕЩ. П1В ОФИС 5 (Офис)
      </span>
      <span>
        Москва, ул.Рябиновая 63, строение 1 (Склад)
      </span>
    </div>
    <div className={styles.email}>
      <a href="/">office@r-trade.org</a>
    </div>
    <div className={styles.connect}>
      <span>+7 (800) 100-5153</span>
      <span>+7 (495) 109-49-79</span>
    </div>
  </div>
);
export default Info;
