import { useState, useEffect } from 'react';
import Star from '../Star/Star';
import styles from './styles.module.scss';

const RatingStars = ({ rating }) => {
  const activeStar = <Star className="active" />;
  const deactiveStar = <Star className="deactive" />;
  const [stars, setStars] = useState([]);

  useEffect(() => {
    if (stars.length < 5) {
      if (stars.length <= rating - 1) {
        setStars((prev) => [...prev, activeStar]);
      } else if (stars.length > rating - 1) {
        setStars((prev) => [...prev, deactiveStar]);
      }
    }
  }, [stars]);

  return (
    <ul className={styles.rating}>
      {stars.map((star, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={i}>
          {star}
        </li>
      ))}
    </ul>
  );
};
export default RatingStars;
