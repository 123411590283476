import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { getSeo } from '../../redux/seo/seoService';
import uri from '../../utils/api/uri';

const SEO = ({ mark }) => {
  const [seo, setSeo] = useState(mark);
  const dispatch = useDispatch();
  const req = useSelector((state) => state.seo.allSeo?.find((el) => el.entity === mark));

  setTimeout(() => {
    if (req && mark === mark.toString()) {
      setSeo(req);
    } else {
      setSeo(mark);
    }
  }, '1');

  useEffect(() => {
    dispatch(getSeo(uri.seo));
  }, []);
  return (
    <Helmet defaultTitle="ecowell">
      <title>{seo?.title}</title>
      <meta name="description" content={seo?.description} />
    </Helmet>
  );
};
export default SEO;
