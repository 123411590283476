import Title from '../generic/Title/Title';
import Info from './Info/Info';
import CustomMap from '../CustomMap/CustomMap';
import styles from './styles.module.scss';

const Contacts = () => (
  <div className={styles.contacts}>
    <div className={styles.container}>
      <Title heading="Контакты" />
      <Info />
    </div>
    <CustomMap />
  </div>
);
export default Contacts;
