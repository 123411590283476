import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Products from '../../components/Products/Products';
import Title from '../../components/generic/Title/Title';
import { getCards } from '../../redux/cardsSlice/cardsService';
// import Filter from '../../components/Products/Filter/Filter';
import SEO from '../../components/SEO/SEO';
import uri from '../../utils/api/uri';

const FacePage = () => {
  const dispatch = useDispatch();
  const { cards } = useSelector((state) => state.cards);
  const [cardsList, setCardsList] = useState([]);
  const face = cardsList.filter((card) => card.category === 'face');
  useEffect(() => {
    if (cards.length === 0) {
      dispatch(getCards(uri.cards));
    } else {
      setCardsList([...cards]);
    }
  }, [cards]);

  return (
    <>
      <SEO mark="face" />
      <Products
        cards={face}
        title={<Title heading="Продукты Для Ухода За Лицом" />}
        // filtering={<Filter />}
      />
    </>
  );
};
export default FacePage;
