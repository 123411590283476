import { Formik, Form, Field } from 'formik';
import styles from './styles.module.scss';

const ModalForm = ({ onHandleSubmit, isFetching }) => (

  <div className={styles['modal-form-wrapper']}>
    <h4 className={styles['modal-form-title']}>Заполните поля ниже чтобы с вами связались наши сотрудники</h4>
    <Formik
      initialValues={{
        firstName: '',
        text: '',
        email: '',
      }}
      onSubmit={(values) => {
        onHandleSubmit(values);
      }}
    >
      <Form>
        <Field id="firstName" name="firstName" placeholder="Имя" />
        <Field id="text" name="text" placeholder="Текст" />
        <Field id="email" name="email" type="email" placeholder="E-mail" />
        <button className={styles.btn} disabled={isFetching} type="submit">Отправить сообщение</button>
      </Form>
    </Formik>
  </div>
);
export default ModalForm;
