import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const setArea = (id) => {
  const areas = ['one', 'two', 'three', 'four', 'five'];
  let areaName = '';
  areas.forEach((el, ind) => {
    switch (id) {
      case ind + 1:
        areaName = el;
        break;
      default:
    }
  });
  return areaName;
};

const CategoryBlock = ({
  pic, mobile, title, id, catLink,
}) => (
  <div
    className={`${styles['category-block']} ${styles[setArea(id)]}`}
  >
    <div className={styles['category-image']}>
      <picture>
        <source media="(max-width: 480px)" srcSet={mobile} />
        <img src={pic} alt="category" />
      </picture>
    </div>
    <Link onClick={() => console.log(catLink)} className={styles['category-title']} to={catLink}>{title}</Link>
  </div>
);
export default CategoryBlock;
