import { createSlice } from '@reduxjs/toolkit';
import { getSeo } from './seoService';

const initialState = {
  allSeo: null,
};

const seoSlice = createSlice({
  name: 'seo',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSeo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSeo.fulfilled, (state, action) => {
        state.allSeo = action.payload;
        state.loading = false;
      })
      .addCase(getSeo.rejected, (state) => {
        state.loading = false;
      });
  },
});
export default seoSlice.reducer;
