import { useState } from 'react';
import Modal from '../../Modal/Modal';
import styles from './styles.module.scss';

const ProdInfo = ({
  description, vendorCode, volume,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className={styles.content}>
      <div className={styles['product-article']}>
        <span className={styles['product-number']}>{!vendorCode ? 'Не задан' : vendorCode}</span>
        <span className={styles['product-volume']}>{!volume ? '150 мл' : `${volume} мл`}</span>
      </div>
      <div className={styles.description}>
        <p>
          {description}
        </p>
      </div>
      <div className={styles.social}>
        <div className={styles['social-link']}>
          <img className={styles['img-link']} src="../pic/social/instagram.svg" alt="instagram" />
        </div>
        <div className={styles['social-link']}>
          <img className={styles['img-link']} src="../pic/social/vk.svg" alt="vk" />
        </div>
        <div className={styles['social-link']}>
          <img className={styles['img-link']} src="../pic/social/dzen.svg" alt="dzen" />
        </div>
      </div>
      <div className={styles['product-btn']}>
        <div className={styles.btn} onClick={() => setIsModalOpen(true)}>Свяжитесь с нами</div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};
export default ProdInfo;
