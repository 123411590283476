import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Products from '../../components/Products/Products';
import Filter from '../../components/Products/Filter/Filter';
import Title from '../../components/generic/Title/Title';
import { getCards } from '../../redux/cardsSlice/cardsService';
import SEO from '../../components/SEO/SEO';

const DiamondPage = () => {
  const dispatch = useDispatch();
  const { cards } = useSelector((state) => state.cards);
  const [cardsList, setCardsList] = useState([]);
  const diamond = cardsList.filter((card) => card.category === 'diamond');

  useEffect(() => {
    if (!cards) {
      dispatch(getCards());
    } else {
      setCardsList([...cards]);
    }
  }, [cards]);
  return (
    <>
      <SEO mark="diamond" />
      <Products
        cards={diamond}
        title={<Title heading="Серия DIAMOND" />}
        filtering={<Filter />}
      />
    </>
  );
};
export default DiamondPage;
