import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const FooterNav = () => (
  <div className={styles['nav-wrapper']}>
    <h4 className={styles['nav-title']}>Меню</h4>
    <nav className={styles.nav}>
      <ul className={styles['nav-list']}>
        <li className={styles['nav-item']}>
          <Link className={styles['nav-link']} to="/about">
            О компании
          </Link>
        </li>
        <li className={styles['nav-item']}>
          <Link className={styles['nav-link']} to="/difference">
            Наша разница
          </Link>
        </li>
        <li className={styles['nav-item']}>
          <Link className={styles['nav-link']} to="/blog">
            Блог
          </Link>
        </li>
      </ul>
    </nav>
  </div>
);
export default FooterNav;
