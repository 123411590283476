import { useState } from 'react';
import Button from '../../generic/Button/Button';
import Modal from '../../Modal/Modal';
import styles from './styles.module.scss';

const Control = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className={styles.control}>
      <div className={styles['control-wrapper']}>
        <img className={styles.cart} src="/pic/icons/cart.svg" alt="cart" />
        <div className={styles.link}>
          <a href="tel:+78001005153">+7 (800) 100-5153</a>
        </div>
        <Button onOpen={() => setIsModalOpen(true)} className={styles['btn-header']} />
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};
export default Control;
