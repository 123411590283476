import {
  YMaps, Map, Placemark, ZoomControl, withYMaps,
} from '@pbe/react-yandex-maps';

const Button = ({ ymaps }) => {
  const onClick = async () => {
    if (ymaps) {
      const data = await ymaps.findOrganization('1671141286');
      console.log(data);
    }
  };
  return <button type="button" onClick={onClick}> get </button>;
};
const ButtonWithYmap = withYMaps(Button, true);

const CustomMap = () => (
  <YMaps
    query={{
      lang: 'en_US',
    }}
  >
    <div>
      <Map
        defaultState={{
          center: [56.301289, 44.029909], zoom: 14.5, controls: [],
        }}
        width="100%"
        height={798}
        instanceRef={(ref) => ref && ref.behaviors.disable('scrollZoom')}
      >
        <Placemark
          geometry={[56.301289, 44.029909]}
          options={{
            preset: 'islands#redDotIcon',
          }}
          properties={{
            iconCaption: 'R-Trade',
          }}
        />
        <ZoomControl options={{
          position: { bottom: 25, right: 0 },
          size: 'small',
        }}
        />
        <ButtonWithYmap />
      </Map>
    </div>
  </YMaps>
);

export default CustomMap;
