import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const Aside = () => (
  <div className={styles.aside}>
    <Link className={styles.logo} to="/">
      <img src="/logo.svg" alt="/logo" />
    </Link>
    <div className={styles.social}>
      <a href="/">
        <img src="../pic/social/instagram.svg" alt="social" />
      </a>
      <a href="/">
        <img src="../pic/social/vk.svg" alt="social" />
      </a>
      <a href="/">
        <img src="../pic/social/dzen.svg" alt="social" />
      </a>
    </div>
  </div>
);
export default Aside;
