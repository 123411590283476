import styles from './styles.module.scss';

const Descr = ({ title, text, classProp }) => (
  <div className={`${styles.descr} ${styles[classProp]}`}>
    <div className={styles['descr-inner']}>
      <h4 className={styles['descr-title']}>{title}</h4>
      {text ? text.map((paragraph) => (
        <p key={paragraph} className={styles['descr-text']}>
          {paragraph}
        </p>
      )) : null}
    </div>
  </div>
);
export default Descr;
