import ProdSlider from '../../ProdSlider/ProdSlider';
import ProdInfo from '../ProdInfo/ProdInfo';
import ProductTitle from '../ProductTitle/ProductTitle';
import styles from './styles.module.scss';

const ProductHead = ({
  images, title, description, volume, vendorCode,
}) => (
  <div className={styles.productHead}>
    <div className={styles.container}>
      <div className={styles['product-head-wrapper']}>
        <ProductTitle title={title} />
        <ProdSlider images={images} />
        <ProdInfo description={description} volume={volume} vendorCode={vendorCode} />
      </div>
    </div>
  </div>
);
export default ProductHead;
