import React from 'react';
import Slider from 'react-slick';
import Slide from './Slide/Slide';
import './styles.scss';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
/* eslint-disable react/jsx-props-no-spreading */
const ProdSlider = ({ images }) => {
  if (!images) return <div>Loading...</div>;
  const customPaging = (i) => (
    <a href="/#">
      <img src={images[i]?.url} alt="slide" />
    </a>
  );

  const settings = {
    customPaging,
    dots: true,
    dotsClass: `slick-dots ${'slick-thumb'}`,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="prodSlider">
      <div className="slider-thumb-wrapper">
        <Slider
          {...settings}
          className="slider-thumb"
        >
          {images.map((img) => <Slide key={img.id} image={img.url} />)}
        </Slider>
      </div>
    </div>
  );
};
export default ProdSlider;
