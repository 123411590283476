import Slider from 'react-slick';
import './styles.scss';
// Import css files
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
/* eslint-disable react/jsx-props-no-spreading */
import FbackSlide from './FbackSlide/FbackSlide';
import { ArrowLeft, ArrowRight } from '../generic/Arrows/Arrows';

const FeedbackSlider = ({ reviews }) => {
  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: reviews.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="feedback-slider-wrapper">
      <h3 className="prod-title">Отзывы о товаре</h3>
      <Slider
        {...settings}
        className="feedback-slider"
      >
        {reviews.map((review) => (
          <FbackSlide
            key={review.card}
            name={review.author}
            text={review.text}
            date={review.creation_date}
            rating={review.rating}
          />
        ))}
      </Slider>
    </div>
  );
};
export default FeedbackSlider;
