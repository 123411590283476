import Subtitle from './Subtitle/Subtitle';
import Content from './Content/Content';
import styles from './styles.module.scss';

const AdviceContent = ({ subtitle, descr, date }) => (
  <div className={styles.content}>
    <Subtitle subtitle={subtitle} />
    {descr && date ? <Content descr={descr} date={date} /> : null}
  </div>
);
export default AdviceContent;
