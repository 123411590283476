import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import AboutPage from './pages/AboutPage/AboutPage';
import BlogPage from './pages/BlogPage/BlogPage';
import ContrastPage from './pages/ContrastPage/ContrastPage';
import MainPage from './pages/MainPage/MainPage';
import ContactsPage from './pages/ContactsPage/ContactsPage';
import styles from './scss/styles.module.scss';
import FacePage from './pages/FacePage/FacePage';
import BodyPage from './pages/BodyPage/BodyPage';
import BabyPage from './pages/BabyPage/BabyPage';
import HairPage from './pages/HairPage/HairPage';
import DiamondPage from './pages/DiamondPage/DiamondPage';
import ProductPage from './pages/ProductPage/ProductPage';
import ScrollToTop from './utils/ScrollToTop';

const App = () => (
  <HelmetProvider>
    <ScrollToTop />
    <div className="App">
      <div className={styles.content}>
        <Header />
        <div id="modal" />
        <div id="privacy" />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/difference" element={<ContrastPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/face" element={<FacePage />} />
          <Route path="/body" element={<BodyPage />} />
          <Route path="/hair" element={<HairPage />} />
          <Route path="/baby" element={<BabyPage />} />
          <Route path="/diamond" element={<DiamondPage />} />
          <Route path="/product/:cardId" element={<ProductPage />} />
        </Routes>
        <Footer />
      </div>
    </div>
  </HelmetProvider>
);

export default App;
