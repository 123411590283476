import { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../Modal/Modal';
import styles from './styles.module.scss';

const MobileMenu = ({ isVisible }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className={`${styles['mobile-menu']} ${isVisible && styles.active}`}>
      <div className={styles['mobile-menu-inner']}>
        <nav className={styles.nav}>
          <ul className={styles['nav-list']}>
            <li className={styles['nav-item']}>
              <Link className={styles['nav-link']} to="/about">
                О компании
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link className={styles['nav-link']} to="/difference">
                Наша разница
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link className={styles['nav-link']} to="/blog">
                Блог
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link className={styles['nav-link']} to="/contacts">
                Контакты
              </Link>
            </li>
          </ul>
        </nav>
        <nav className={`${styles.nav} ${styles['nav-category']}`}>
          <ul className={styles['nav-list']}>
            <li className={`${styles['nav-item']} ${styles.first}`}>
              <Link className={styles['nav-link']} to="/face">
                Продукты для ухода за лицом
              </Link>
            </li>
            <li className={`${styles['nav-item']} ${styles.second}`}>
              <Link className={styles['nav-link']} to="/body">
                Уход за телом
              </Link>
            </li>
            <li className={`${styles['nav-item']} ${styles.third}`}>
              <Link className={styles['nav-link']} to="/hair">
                Уход за волосами
              </Link>
            </li>
            <li className={`${styles['nav-item']} ${styles.fourth}`}>
              <Link className={styles['nav-link']} to="/baby">
                Продукты по уходу за ребенком
              </Link>
            </li>
            {/* <li className={`${styles['nav-item']} ${styles.fifth}`}> */}
            {/*  <Link className={styles['nav-link']} to="/diamond"> */}
            {/*    Серия DIAMOND */}
            {/*  </Link> */}
            {/* </li> */}
          </ul>
        </nav>
        <button
          onClick={() => setIsModalOpen(true)}
          type="button"
          className={styles.btn}
        >
          Свяжитесь с нами
        </button>
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </div>
  );
};
export default MobileMenu;
