import styles from './styles.module.scss';

const AdviceBlock = ({ pic, children }) => (
  <div className={styles['advice-block']}>
    <div className={styles['advice-image']}>
      <img src={pic} alt="advice" />
    </div>
    {children}
  </div>
);
export default AdviceBlock;
