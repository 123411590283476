import styles from './styles.module.scss';

const Content = ({ descr, date }) => (
  <div className={styles.content}>
    <span className={styles.descr}>
      {descr}
    </span>
    <aside className={styles.aside}>
      <span className={styles['aside-info']}>
        <span className={styles['aside-date']}>{date}</span>
        <img src="./pic/icons/chat.svg" alt="dialog" />
      </span>
      <button type="button" className={`${styles.btn}`}>Читать больше</button>
    </aside>
  </div>
);
export default Content;
