import { createSlice } from '@reduxjs/toolkit';
import { getCards } from './cardsService';

const initialState = {
  cards: [],
  loading: false,
};

export const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCards.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCards.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cards = payload;
      })
      .addCase(getCards.rejected, (state) => {
        state.loading = false;
      });
  },
});
export default cardsSlice.reducer;
