import Collage from '../../components/Collage/Collage';
import Intro from '../../components/Intro/Intro';
import styles from './styles.module.scss';
import Descr from '../../components/Collage/Descr/Descr';
import Image from '../../components/Collage/Image/Image';
import Category from '../../components/Category/Category';
import SEO from '../../components/SEO/SEO';

const data = {
  intro: {
    pic: './pic/intro/intro-bg_2.jpg',
    title: 'О НАС',
  },
  collage: {
    pic1: './pic/collage/collage-img_3.jpg',
    descr: [
      {
        id: 1,
        title: 'КОГДА В ПОСЛЕДНИЙ РАЗ ВЫ ОКАЗЫВАЛИ СЕБЕ УСЛУГУ?',
        text: ['Когда вы поддерживали свое тело утренними прогулками, свою иммунную систему натуральными питательными веществами ... или когда вы в последний раз наблюдали за небом под деревьями? Все это моменты, которые будут питать и очищать как вашу душу, так и ваше тело ... Да, время становится ценным с тем, как вы его используете и с вашими предпочтениями. Начните ценить свое время и себя, которых вы заслуживаете, прямо сейчас!'],
      },
      {
        id: 2,
        title: 'ЭКОВЕЛЛ ЗОВЕТ ВАС НА ВСТРЕЧУ С ПРИРОДОЙ!',
        text: [
          'Вы хотите делать себе одолжение каждый день? Тогда прислушайтесь к голосу Эковелла. Пришло время по-настоящему позаботиться о своей коже и волосах, вернуть то, что отняли годы, избавить их от воздействия химических веществ, которым они подвергались до сих пор.',
          'Сертифицированные ICEA продукты Ecowell с натуральными и органическими ингредиентами предлагают вам богатые природные ресурсы. Ваша кожа и волосы становятся здоровыми, защищенными и выглядят более ухоженными, чем когда-либо, благодаря продуктам с натуральными и органическими ингредиентами.',
        ],
      },
      {
        id: 3,
        title: 'Наша разница',
        text: [
          'Продукты Ecowell сертифицированы ICEA, являются натуральными и органическими.Продукты Ecowell никогда не содержат вредных для здоровья ингредиентов.',
        ],
      },
    ],
    pic2: './pic/collage/collage-img_4.jpg',
    pic3: './pic/collage/collage-img_1.jpg',
    pic4: './pic/collage/collage-img_2.jpg',
  },
  category: {
    categories: [
      {
        id: 1,
        pic: './pic/category/category-img_1.jpg',
        title: 'Продукты По Уходу За Ребенком',
        catLink: '/baby',
      },
      {
        id: 2,
        pic: './pic/category/category-img_2.jpg',
        title: 'Продукты По Уходу За Волосами',
        catLink: '/hair',
      },
      {
        id: 3,
        pic: './pic/category/category-img_3.jpg',
        title: 'Продукты По Уходу За Телом',
        catLink: '/body',
      },
      {
        id: 4,
        pic: './pic/category/category-img_4.jpg',
        title: 'Продукты По Уходу За Лицом',
        catLink: '/face',
      },
      {
        id: 5,
        pic: './pic/category/category-img_5.jpg',
        mobile: './pic/category/category-img_5-480w.jpg',
        title: 'Продукты По Уходу За Ребенком',
        catLink: '/baby',
      },

    ],
  },
};
const AboutPage = () => (
  <div className={styles.content}>
    <SEO mark="about" />
    <Intro pic={data.intro.pic} title={data.intro.title} />
    <Collage bgColor="#fff">
      <Descr title={data.collage.descr[0].title} text={data.collage.descr[0].text} classProp="first" />
      <Image pic={data.collage.pic1} classProp="first" />
      <Image pic={data.collage.pic2} classProp="second" />
      <Descr title={data.collage.descr[1].title} text={data.collage.descr[1].text} classProp="second" />
    </Collage>
    <Collage bgColor="#F8F8FA">
      <Descr title={data.collage.descr[2].title} text={data.collage.descr[2].text} />
      <Image pic={data.collage.pic3} classProp="first" />
      <Image pic={data.collage.pic4} classProp="second" />
    </Collage>
    <Category categories={data.category.categories} />
  </div>
);
export default AboutPage;
